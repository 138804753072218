import React, { useState, useRef } from "react"
import Chveron from "./Chevron"

const Step = ({ title, description }) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("accordion__icon")
  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    )
  }

  return (
    <div className="how-step">
      <div className="how-step__title">
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
          <h2>{title}</h2>
          <Chveron className={`${setRotate}`} width={10} fill={"#35C567"} />
        </button>
      </div>
      <div
        className="how-step__content"
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

export default Step
