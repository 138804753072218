import React from "react"
import styled from "styled-components"

import {
  MedWrapper,
  headlineOne,
  headlineTwo,
  bodyCopy,
} from "../../../Utilities"
import SwitchbackTB from "../../../Elements/Images/Logos/SwitchbackTB"

const IntroSection = styled.section`
  .intro-wrapper {
    ${MedWrapper};
  }

  .tbintro-content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(65% - 2rem);
      margin-left: 2rem;
    }

    &__title {
      h2 {
        ${headlineOne};
        margin-top: 0;
        margin-bottom: 2.5rem;
        text-transform: uppercase;
      }

      p {
        ${headlineTwo};
        margin-bottom: 2.5rem;
        color: #5c5c5c;
      }
    }

    &__body {
      p {
        ${bodyCopy};
      }
    }
  }

  .tbintro-logo {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(35% - 2rem);
      margin-right: 2rem;
    }

    &__container {
      margin-top: 1.25rem;
      max-width: 35rem;
    }
  }
`

const Intro = () => {
  return (
    <IntroSection>
      <div className="intro-wrapper">
        <div className="tbintro-logo">
          <div className="tbintro-logo__container">
            <SwitchbackTB />
          </div>
        </div>
        <div className="tbintro-content">
          <div className="tbintro-content__title">
            <h2>Non-Profit Award</h2>
            <p>
              Here is everything you need to know about this process and award.
            </p>
          </div>
          <div className="tbintro-content__body">
            <p>
              Switchback Creative originally started the Trailblazer Fund to
              give a hand up to young entrepreneurs with a new business idea.
              After the first 4 years we realized that we wanted to make a
              bigger impact with established organizations already making the
              world a better place.
            </p>
            <p>
              Once a year we are looking for organizations that are doing really
              great work but have marketing, design or branding challenges. This
              is a TRAILBLAZER PROGRAM FOR REGISTERED NON-PROFITS AND SOCIETIES.
            </p>
            <p>
              Are you making the world a better place, do you have a big vision
              for your organization but you need some creative expertise to help
              you solve challenges you have? We are looking for collaborative
              relationships that are a great win-win for both parties. Great
              work, great people.
            </p>
          </div>
        </div>
      </div>
    </IntroSection>
  )
}

export default Intro
