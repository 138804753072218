import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { headlineTwo } from "../../../Utilities"
import TBLogo from "../Services/TBLogo"
import StandardWrapper from "../../Shared/StandardWrapper"

const getData = graphql`
  {
    tbLogos: allWordpressWpTrailblazer(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          id
          acf {
            _swb_link
            _swb_logo {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const PastWinnersSection = styled.section`
  position: relative;
  padding: 0 0 5rem;

  .past-title {
    width: 100%;

    h2 {
      ${headlineTwo};
      margin: 0;
      color: #5c5c5c;
    }
  }

  .logos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const PastWinners = () => {
  const data = useStaticQuery(getData)
  const { tbLogos } = data

  return (
    <PastWinnersSection>
      <StandardWrapper>
        <div className="past-title">
          <h2>Past Winners</h2>
        </div>
        <div className="logos-container">
          {tbLogos.edges.map(logo => {
            return <TBLogo key={logo.node.id} logo={logo.node} />
          })}
        </div>
      </StandardWrapper>
    </PastWinnersSection>
  )
}

export default PastWinners
