import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const TBLogoStyled = styled.div`
  width: calc(50% - 6rem);
  margin: 2rem 3rem;
  align-self: center;

  @media (min-width: 768px) {
    width: calc(25% - 3rem);
    margin: 2rem 1.5rem;
  }

  @media (min-width: 1025px) {
    width: calc(25% - 6rem);
    margin: 2rem 3rem;
  }
`

const TBLogo = props => {
  const { logo } = props

  return (
    <TBLogoStyled>
      {logo.acf._swb_link !== "" ? (
        <a href={logo.acf._swb_link}>
          <Img
            fluid={logo.acf._swb_logo.localFile.childImageSharp.fluid}
            alt={logo.title}
          />
        </a>
      ) : (
        <div>
          <Img
            fluid={logo.acf._swb_logo.localFile.childImageSharp.fluid}
            alt={logo.title}
          />
        </div>
      )}
    </TBLogoStyled>
  )
}

export default TBLogo
