import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { bodyCopy, colors, fonts, fontSizer } from "../../Utilities"
import TreeOneSvg from "../../assets/h-tree1.svg"
import TreeTwoSvg from "../../assets/h-tree2.svg"
import MountainOneSvg from "../../assets/h-mountain1.svg"
import MountainTwoSvg from "../../assets/h-mountain2.svg"
import MountainThreeSvg from "../../assets/h-mountain3.svg"
import MountainFourSvg from "../../assets/h-mountain4.svg"
import TentSvg from "../../assets/h-tent.svg"
const getData = graphql`
  {
    tests: allWordpressWpTestimonials(sort: { fields: [date], order: [DESC] }) {
      edges {
        node {
          id
          acf {
            testimonial
            company
          }
        }
      }
    }
  }
`

const TestimonailsSection = styled.section`
  position: relative;
  width: 100%;
  height: 75vh;
  min-height: 75rem;
  background: #c7d3db;
  overflow: hidden;
`

const TreeBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  z-index: 7;

  svg {
    width: 100%;
    max-width: 100%;
  }

  &.tree-one {
    right: 5vw;
    width: 3.5vw;
    height: 100%;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -45%;
    }
  }

  &.tree-two {
    right: 23vw;
    width: 5.5vw;
    height: 100%;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -42%;
    }
  }

  &.tree-three {
    right: 15vw;
    width: 7vw;
    height: 100%;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -40%;
    }
  }

  &.tree-four {
    left: 5vw;
    width: 5vw;
    height: 100%;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -42%;
    }
  }

  &.tree-five {
    left: 12.5vw;
    width: 7vw;
    height: 100%;
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -40%;
    }
  }

  &.tree-six {
    left: 45vw;
    width: 4vw;
    height: 100%;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -44%;
    }
  }
`

const MountainBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  z-index: 3;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    bottom: -10%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }

  &.mountain-one {
    right: 10vw;
    width: 38vw;
    height: 100%;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -10%;
    }
  }

  &.mountain-two {
    right: 2vw;
    width: 30vw;
    height: 100%;
    z-index: 1;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -15%;
    }
  }

  &.mountain-three {
    left: 30vw;
    width: 27vw;
    height: 100%;
    z-index: 1;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -18%;
    }
  }

  &.mountain-four {
    left: 37.5vw;
    width: 30vw;
    height: 100%;
    z-index: 6;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -26%;
    }
  }

  &.mountain-five {
    left: 5vw;
    width: 40vw;
    height: 100%;
    z-index: 5;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      bottom: -15%;
    }
  }
`

const TentBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  left: 30vw;
  width: 12vw;
  height: 12vw;
  z-index: 11;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    bottom: -5%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const TestimonailsContent = styled.div`
  position: absolute;
  top: 5rem;
  right: 1rem;
  left: 1rem;
  min-height: 45rem;
  padding: 1.5rem;
  background: #fff;
  z-index: 25;

  @media (min-width: 768px) {
    top: 4rem;
    right: 4rem;
    left: 4rem;
    min-height: 40rem;
    padding: 4rem;
  }

  @media (min-width: 1025px) {
    width: 85.5rem;
    min-height: 46.8rem;
    top: 4rem;
    right: auto;
    left: 4rem;
    padding: 8rem;
  }

  .test-title {
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      margin-bottom: 5rem;
    }
    h2 {
      ${bodyCopy};
      margin: 0;
      color: ${colors.colorSecondary};
      text-transform: uppercase;
    }
  }

  .test-company,
  .test-content {
    margin-bottom: 2.5rem;
    @media (min-width: 768px) {
      margin-bottom: 5rem;
    }
    p {
      ${bodyCopy};
      ${fontSizer(2, 2.6, 76.8, 100, 2)};
      color: #000;
      font-family: ${fonts.fontTertiary};
    }

    p:last-of-type {
      margin: 0;
    }
  }

  .test-company {
    margin: 0;
  }
`

const Testimonials = props => {
  const data = useStaticQuery(getData)

  const {
    tests: { edges: testimonials },
  } = data

  const randNum = Math.floor(Math.random() * testimonials.length)
  const testimonial = testimonials[randNum].node.acf
  return (
    <TestimonailsSection>
      <TestimonailsContent>
        <div className="test-title">
          <h2>Words from smart people</h2>
        </div>
        <div
          className="test-content"
          dangerouslySetInnerHTML={{ __html: testimonial.testimonial }}
        />
        <div className="test-company">
          <p>{testimonial.company}</p>
        </div>
      </TestimonailsContent>

      <div>
        <div>
          <TreeBase className="tree-one">
            <TreeOneSvg />
          </TreeBase>
          <TreeBase className="tree-two">
            <TreeTwoSvg />
          </TreeBase>
          <TreeBase className="tree-three">
            <TreeOneSvg />
          </TreeBase>
          <TreeBase className="tree-four">
            <TreeOneSvg />
          </TreeBase>
          <TreeBase className="tree-five">
            <TreeTwoSvg />
          </TreeBase>
          <TreeBase className="tree-six">
            <TreeTwoSvg />
          </TreeBase>
        </div>

        <div>
          <MountainBase className="mountain-one">
            <MountainThreeSvg />
          </MountainBase>
          <MountainBase className="mountain-two">
            <MountainFourSvg />
          </MountainBase>
          <MountainBase className="mountain-three">
            <MountainFourSvg />
          </MountainBase>
          <MountainBase className="mountain-four">
            <MountainOneSvg />
          </MountainBase>
          <MountainBase className="mountain-five">
            <MountainTwoSvg />
          </MountainBase>
        </div>

        <div>
          <TentBase>
            <TentSvg />
          </TentBase>
        </div>
      </div>
    </TestimonailsSection>
  )
}

export default Testimonials
