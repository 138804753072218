import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import OfficeScene from "../components/PageParts/TrailblazerFund/HeaderScene"
import Intro from "../components/PageParts/TrailblazerFund/Intro"
import HowItWorks from "../components/PageParts/TrailblazerFund/HowItWorks"
import PastWinners from "../components/PageParts/TrailblazerFund/PastWinners"
import Testimonials from "../components/Shared/Testimonials"

const TrailblazerFund = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Trailblazer Fund for Non-Profits - Switchback Creative"
        description="We are looking for non-profit organizations that are doing really great work but have marketing, design and/or branding challenges. We can help! Apply today."
        location={props.location.pathname}
        pageImg={props.data.metaImage.publicURL}
      />
      <OfficeScene />
      <Intro />
      <HowItWorks />
      <PastWinners />
      <Testimonials />
    </Layout>
  )
}

export const trailBlazerQuery = graphql`
  {
    metaImage: file(relativePath: { eq: "switchback-trailblazer-meta.jpg" }) {
      publicURL
    }
  }
`

export default TrailblazerFund
