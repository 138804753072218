export default [
  {
    title: "how it works",
    description:
      "<div><h4>What we provide</h4><p>Free access to our marketing expertise, collaborating with recipients to bring them quality branding, design and web development services that will take your organization to the next level. We also want to help you with marketing strategy and thinking to start the planning process. We will work with you to present your brand in the best light possible and provide strategic marketing assistance. We will help you blaze a trail to greater heights based on a foundation that has already been set, or a vision taking shape in your organization.</p><p>We are hoping for a long term relationship that starts with this Trailblazer Fund and grows into lifelong friendship between our companies.</p><p>TRAILBLAZER PACKAGE INCLUDES $10,000 WORTH OF DESIGN, BRANDING OR WEBSITE DEVELOPMENT</p></div><div><p>The two options for package services are:</p><p>1. Branding Development package: Creation of a new logo, branding elements go with the logo, all logo files in a variety of formats, style guide, brand guidelines book, sample ad template, social media graphics, business card design, social graphics, email signature, letterhead and postcard for general information, 2 custom template pieces to suit your needs.</p><p>2. Website Development package: new website development and training on website, one on one session for our compass workshop with you and your team.</p><p>Organizations have the opportunity to gain insight into the operations of a full marketing and web agency, collaborate on creative briefs, brainstorming and approvals, at many stages along the way. We want to help educate and support your team, in understanding the behind the scenes on how it all works.</p></div>",
  },
  {
    title: "Who is eligible",
    description:
      "<div><p>Official registered societies and non-profit organizations that are looking for a friendly team of experts to help solve problems and take your brand to the next level.</p></div>",
  },
  {
    title: "Applications",
    description:
      "<div><p>We want you to be so passionate about your organization that you would go out of your comfort zone to make it a reality. How can you demonstrate that to us? Share with us a video and written pitch on why we should love your organization too and what you need help with.</p><p>Please include the following in your application, these are all required: </p><ul><li>Submit a 1-3 minute video pitch about why you should be the next Trailblazer winner.</li><li>Submit a 300-word minimum, brief on your situation with the following questions answered: <ul><li>How does your organization make the world and our community a better place?</li><li>How would the TrailBlazer program impact your organization?</li><li>Demonstrate your need for the branding or web services we offer.</li><li>Explanation of the organizations goals for the next 2-5 years.</li></ul></li><li>Links to your website/social media profiles, if you have them currently</li><li>Tell us about your hopes for the future and why you need our help</li><li>Please include Your Registered Charity Number. This is required.</li></ul><p>Please email all of this in PDF format and a link to Youtube  or Vimeo to trailblazer@switchbackcreative.ca.</p><p> We can’t wait to hear from you!</p></div>",
  },
  {
    title: "Timeline",
    description:
      "<div><ul><li>Sept. 15 – Nov. 15: Applications are open to all, closes at noon Nov. 15.</li><li>Nov. 15 – Dec. 14: Narrow down to maximum 3 finalists ­– conduct interviews with the Switchback Team according to scoring sheet and criteria</li><li>Dec. 15: Winner is chosen and announced</li><li>Jan. 15: Kickoff meeting with successful organization</li></ul></div>",
  },
  {
    title: "Scoring",
    description:
      "<div><p>Scoring criteria for the finalists and interviews is based on:</p><ul><li>Quality and effort of the pitch</li><li>Demonstration of how your organization helps to make the world a better place</li><li>How much impact could the TrailBlazer program can have on the organization?</li><li>Demonstration of the Organization’s need of the services we offer</li><li>Explanation of the organization goals for the next 1-5 years</li></ul></div>",
  },
]
