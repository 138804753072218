import React from "react"
import styled from "styled-components"

import {
  MedWrapper,
  fontSizer,
  colors,
  bodyCopy,
  fonts,
} from "../../../Utilities"
import steps from "./StepsData/steps"
import Step from "./Step"

const HowItWorksSection = styled.section`
  .how-wrapper {
    ${MedWrapper};
  }

  .how-details,
  .how-steps {
    width: calc(100%);
    @media (min-width: 768px) {
      width: calc(65% - 2rem);
      margin-left: calc(35% + 2rem);
      margin-bottom: 5rem;
    }
  }

  .how-step {
    margin: 0;
    padding: 0;
    overflow: hidden;

    &__title {
      margin-bottom: 1rem;
      border-bottom: 0.1rem solid ${colors.black};

      .accordion {
        width: 100%;
        background-color: #eee;
        color: #444;
        cursor: pointer;
        padding: 18px;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;
      }

      .accordion:hover,
      .active {
        background-color: #004565;
      }

      h2 {
        ${fontSizer(2, 2.6, 76.8, 140, 2.2)};
        margin: 0;
        color: ${colors.colorSecondary};
        text-transform: uppercase;
      }

      .accordion__icon {
        margin-left: auto;
        transition: transform 0.6s ease;
      }

      .rotate {
        transform: rotate(90deg);
      }
    }

    &__content {
      background-color: white;
      overflow: hidden;
      transition: max-height 0.6s ease;

      h4 {
        ${bodyCopy};
        margin-bottom: 0;
        color: #585858;
        font-weight: bold;
      }

      p,
      li {
        ${bodyCopy};
        display: block;
        color: #585858;
      }
      ul {
        margin: 2rem auto;

        li {
          position: relative;
          margin-bottom: 1rem;
          padding-left: 2rem;

          &::before {
            position: absolute;
            top: 0.775rem;
            left: 0.5rem;
            font-family: ${fonts.fontAwesome};
            font-size: 0.5em;
            content: "\f111";
          }
          ul {
            li {
              &::before {
                content: "\f10c";
              }
            }
          }
        }
      }
    }
  }

  .how-details {
    ${bodyCopy};
    display: block;
    color: #585858;
  }
`

const HowItWorks = () => {
  return (
    <HowItWorksSection>
      <div className="how-wrapper">
        <div className="how-steps">
          {steps.map((step, index) => {
            return (
              <Step
                key={index}
                title={step.title}
                description={step.description}
              />
            )
          })}
        </div>
        <div className="how-details">
          <p>
            We will provide you with the knowledge and tools to make your
            marketing successful with your whole team and organization. Timing
            for project execution can be discussed at the time of the
            interviews.
          </p>
          <p>
            More extensive collaboration is available if the organization is
            interested, but may have a cost associated. You will be informed of
            any charges upfront, this may be the case for ongoing support and
            back up for websites once completed. Please note, you are
            responsible for all third party costs i.e. purchasing website URLs
            and hosting; printing costs, etc. but this will be discussed before
            hand.
          </p>
          <p>
            Please send any questions about this TrailBlazer program to
            trailblazer@switchbackcreative.ca
          </p>
          <p>
            Applications open September 15 and close November 15. Best of luck
            to all the applicants, we can’t wait to hear your story!
          </p>
        </div>
      </div>
    </HowItWorksSection>
  )
}

export default HowItWorks
